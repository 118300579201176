@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-all-courses {
  &__date-filter {
    align-items: end;
    &__week-nav {
      @media only screen and (max-width: 600px) {
        align-self: start;
      }
      button {
        background-color: transparent;
        border-color: transparent;
        color: $els-color-secondary;
      }
      span {
        font-size: $els-fs-h2;
      }
    }
    &__date-picker-apply > *, &__back-to-current {
      @media only screen and (max-width: 600px) {
        padding-top: $els-space-1o2;
      }
    }
    &__apply {
      background-color: $els-color-background;
      border-color: $els-color-secondary;
      color: $els-color-text;
    }
    &__error-message {
      align-self: start;
      color: $els-color-warn;
    }
  }
}
